import { Observable, BehaviorSubject } from "rxjs";
import { Injectable, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataAccessHelpersService } from "./data-access-helpers.service";
import { firestore } from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ParcAccessService {
  private parcSource = new BehaviorSubject("");
  currentParc = this.parcSource.asObservable();
  API_ENDPOINT = environment.API_ENDPOINT;

  headers = { "Content-Type": "application/json" };

  isLoadingEvent: EventEmitter<any> = new EventEmitter<any>();
  isLoading = false;
  toggleLoading() {
    this.isLoading = !this.isLoading;
    this.isLoadingEvent.emit(this.isLoading);
  }

  getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken(true);
  }

  changeParc(parc: any) {
    this.parcSource.next(parc);
  }
  getMostUsedCodes(parc: string) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getTopCodes/?parc=${parc}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }
  getCredentials(parc: string) {
    return this.dhs.getDocumentOnce(`externalCredentials/${parc}`);
  }
  getAllCredentials() {
    return this.dhs.getCollectionOnce("externalCredentials");
  }
  sendCredentials(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/setupCredentials`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getOdooAPI(parc: string, type: string) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(
          `${this.API_ENDPOINT}/getOdooAPI?parcId=${parc}&type=${type}`,
          {
            headers: { ...this.headers, Authorization: idToken },
            method: "GET",
          }
        );
      })
      .catch((err) => Promise.reject(err));
  }

  setOdooAPI(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/setOdooAPI`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  sendMove(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/moves`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  sendInterchange(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/sendInterchange`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  saveLog(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/logs`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getInspectionTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "inspection")
      .get();
  }

  getRepairTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "repair")
      .limit(1)
      .get();
  }
  getCustomersOnce() {
    return this.dhs.getCollectionOnce("customers");
  }
  getCustomers() {
    return this.dhs.getCollection("customers");
  }
  getAllParcs(): Promise<IParc[]> {
    return this.dhs.getCollectionOnce("yards");
  } // filter cfs parcs
  async getAllCfsParcs() {
    let allParcs = await this.dhs.getCollectionOnce("yards");
    let cfsParcs = allParcs.filter((parc) => parc.cfs);
    return cfsParcs;
  }
  getYardOnceById(id: string): Promise<IParc> {
    return this.dhs.getDocumentOnce(`yards/${id}`);
  }
  getYardSubscribe(id: string): Observable<IParc> {
    return this.dhs.getDocument(`yards/${id}`);
  }
  setYardFournisseurs(
    parc: string,
    fournId: string,
    data: Partial<IFournisseur>
  ) {
    return this.afs
      .collection(`yards/${parc}/fournisseurs`)
      .doc(fournId)
      .set(data);
  }
  setYardClients(parc: string, clientId: string, data: Partial<IClient>) {
    return this.afs.collection(`yards/${parc}/clients`).doc(clientId).set(data);
  }
  getInterchangeDocumentsDone(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhereByOrderWithLimit(
      `yards/${parc}/interchanges`,
      [{ left: "printable", operator: "==", right: true }],
      "date",
      "desc",
      3000
    );
  }
  getPendingBays(parc: string){
    return this.dhs.getCollectionOnce(`yards/${parc}/pendingBays`);
  }

  getLastReservedPiece(parcId: string, refPiece: string) {
    return this.afs
      .collection(`yards/${parcId}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refPiece)
      .orderBy("lastIncrement", "desc")
      .get();
  }
  getInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }
  getLatestInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .orderBy("date", "desc")
      .limit(1)
      .get();
  }
  getDevisByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/devis`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }
  setOrder(parc: string, order) {
    return this.afs.collection(`yards/${parc}/orders`).add(order);
  }
  getAllOrders(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/orders`);
  }
  getAllBills(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/devis`);
  }
  getOrders(parc: string) {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/orders`,
      "createdAt",
      "desc"
    );
  }

  getYardClients(parc: string): Observable<IClient[]> {
    return this.dhs.getCollection(`yards/${parc}/clients`);
  } // filter cfs clients (isBCO=true)
  getYardCFSclients(parc: string): Observable<IClient[]> {
    const clientsCFS = this.dhs.getCollectionWhere(`yards/${parc}/clients`, [
      { left: "isBCO", operator: "==", right: true },
    ]);

    return clientsCFS;
  }
  getYardClientsOnce(parc: string): Promise<IClient[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/clients`);
  }

  getInterchangeDocumentsPending(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/interchanges`, [
      { left: "printable", operator: "==", right: false },
    ]);
  }

  getIncompletedGatesOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/incompleteGate`);
  }
  getIncompletedGates(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/incompleteGate`);
  }

  getYardFournisseur(parc: string): Observable<IFournisseur[]> {
    return this.dhs.getCollection(`yards/${parc}/fournisseurs`);
  }
  getAllTransportersOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/fournisseurs`);
  }
  getYardFournisseurOnce(parc: string, fournId: string): Promise<any> {
    return this.dhs.getDocumentOnce(`yards/${parc}/fournisseurs/${fournId}`);
  }
  getTruckingCompanyByPlate(parc: string, plate: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")
      .ref.where("trucks", "array-contains", plate)
      .limit(1)
      .get();
  }
  getTruckingCompanyByParc(parc: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")

      .get();
  }
  deleteYardFournisseur_Client(path: string) {
    return this.afs.doc(path).delete();
  }
  deleteContract(path: string) {
    return this.afs.doc(path).delete();
  }
  updateYardFournisseur(path: string, data: Partial<IFournisseur>) {
    return this.afs.doc(path).update(data);
  }
  updateYardCfsClient(parc: string, id: string, data: Partial<IClient>) {
    return this.afs.doc(`yards/${parc}/clients/${id}`).update(data);
  }
  updateYardClient(parc: string, id: string, data: Partial<IClient>) {
    return this.afs.doc(`yards/${parc}/clients/${id}`).update(data);
  }
  setYardData(parc: string, data: Partial<IParc>) {
    return this.afs.doc(`yards/${parc}`).update(data);
  }

  postContainer(parc: string, numSerie: string, data: IContainer) {
    return this.afs
      .collection(`yards/${parc}/containers`)
      .doc(`${numSerie}`)
      .set(data);
  }
  postTask(parc: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").add(data);
  }
  postTaskwithID(parc: string, id: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").doc(id).set(data);
  }
  setStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .collection(`yardPlans/${parcId}/stackingRules`)
      .doc(bayId)
      .set(data);
  }
  deleteStackingRule(parcId: string, bayId: string) {
    return this.afs.doc(`yardPlans/${parcId}/stackingRules/${bayId}`).delete();
  }
  getStackingRule(parcId: string, bayId: string) {
    return this.dhs.getDocumentOnce(
      `yardPlans/${parcId}/stackingRules/${bayId}`
    );
  }
  getStackingRules(parcId: string) {
    return this.dhs.getCollectionOnce(`yardPlans/${parcId}/stackingRules`);
  }
  updateStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .doc(`yardPlans/${parcId}/stackingRules/${bayId}`)
      .update(data);
  }

  postInterchange(parc: string, data: Partial<IInterchange>, taskId: string) {
    return this.afs
      .doc(`yards/${parc}`)
      .collection("interchanges")
      .doc(taskId)
      .set(data);
  }
  getYardPlanOnce(yardId: string): Promise<IPlan> {
    return this.dhs.getDocumentOnce(`/yardPlans/${yardId}`);
  }
  getYardPlan(yardId: string): Observable<IPlan> {
    return this.dhs.getDocument(`yardPlans/${yardId}`);
  }

  updateYardPlan(yardId: string, plan: Partial<IPlan>) {
    return this.afs.doc(`/yardPlans/${yardId}`).update(plan);
  }

  autoCompleteForm(parc: string) {
    return this.afs
      .collection(`/yards/${parc}/tasks`)
      .ref.where("type", "==", "gateFromOCR")
      .where("inProgress", "==", false)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }
  setCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).set(data);
  }
  updateCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).update(data);
  }
  deleteCurrencyRate(id) {
    return this.afs.collection("exchangeRates").doc(id).delete();
  }
  getCurrencyRates() {
    return this.dhs.getCollection("exchangeRates");
  }
  getCurrencyRate(id) {
    return this.dhs.getDocumentOnce(`exchangeRates/${id}`);
  }
  updateOCRTask(parc: string, docId: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).update(data);
  }
  deleteTask(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).delete();
  }
  getTaskByPrenotifRef(parc: string, prenotifId: string) {
    return this.afs
      .collection(`yards/${parc}/tasks`)
      .ref.where("prenotifId", "==", prenotifId)
      .get();
  }
  deleteTaskByPrenotifRef(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).delete();
  }
  deleteDevis(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/devis/${docId}`).delete();
  }
  getPlacementTasks(parc: string): Observable<ITask[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/tasks`, [
      { left: "type", operator: "in", right: ["placement"] },
    ]);
  }
  getPlacementTasksOnce(parc: string) {
    return this.afs
      .collection(`yards/${parc}/tasks`)
      .ref.where("type", "==", "placement")
      .get();
  }

  getStockInventroyByLastDec(parc: string): Observable<any[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/spare-parts-inventory`,
      "lastDecrement",
      "desc"
    );
  }
  getStockInventroy(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/spare-parts-inventory`);
  }
  getStockInventroyOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/spare-parts-inventory`);
  }
  getRelatedTasks(yardId, billId) {
    return this.afs
      .collection("yards")
      .doc(yardId)
      .collection("tasks")
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getPiece(parc, refP) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refP)
      .orderBy("integrationTime")
      .get();
  }
  getPendingTasks(parc, billId) {
    return this.afs
      .collection(`yards/${parc}/pendingTasks`)
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getFinishedMachineryRepairs(parcId) {
    return (
      this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("type", "==", "devisM")
        //.where("reparationReef", ">", 0)
        .where("reefRepairDate", ">", 0)
        .where("status", "==", "pending")
        .get()
    );
  }
  getFinishedBoxRepairs(parcId) {
    return (
      this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("type", "==", "devis")
        //.where("reparationBox", ">", 0)
        .where("boxRepairDate", ">", 0)
        .where("status", "==", "pending")
        .get()
    );
  }
  getPieceInRepairTask(parcId) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("tasks")
      .ref.where("type", "==", "repair")
      .get();
  }
  getPieceByVendor(parc, refP, vendor, stockUnit?) {
    return (
      this.afs
        .collection(`yards/${parc}/spare-parts-inventory`)
        .ref.where("refPiece", "==", refP)
        .where("vendor", "in", [vendor, ""])
        //.where("stockUnit",'==',stockUnit)
        .orderBy("integrationTime")
        .limit(1)
        .get()
    );
  }
  getOutOfStockParts(parc) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("availableQuantity", "<=", 0)
      .get();
  }

  setPiece(parc, data) {
    return this.afs.collection(`yards/${parc}/spare-parts-inventory`).add(data);
  }
  decrementStock(path, decr) {
    const decrement = firestore.FieldValue.increment(decr * -1);
    return this.afs.doc(path).update({ availableQuantity: decrement });
  }
  getAllTasks(parc: string): Promise<ITask[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/tasks`);
  }

  setBooking(parc: string, id: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/booking`).doc(id).set(data);
  }
  getBooking(parc: string): Observable<IBooking[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/booking`,
      "createdAt",
      "desc"
    );
  }
  getBookingOnce(parc: string): Promise<IBooking[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/booking`);
  }
  getDossierOnce(parc: string): Promise<IBCO[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/dossiersBCO`);
  }
  getSpecificBooking(parc: string, idBooking: string) {
    return this.dhs.getDocumentOnce(`yards/${parc}/booking/${idBooking}`);
  }
  deleteBooking(parc: string, id: string) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).delete();
  }
  updateBooking(parc: string, id: string, data: Partial<IBooking>) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).update(data);
  }
  deleteBCO(parc: string, id: string) {
    return this.afs.doc(`yards/${parc}/dossiersBCO/${id}`).delete();
  }
  updateBCO(parc: string, id: string, data: Partial<IBCO>) {
    return this.afs.doc(`yards/${parc}/dossiersBCO/${id}`).update(data);
  }
  deleteReport(path: string) {
    return this.afs.doc(path).delete();
  }
  updateReport(path: string, data: Partial<IAuthorizedReports>) {
    return this.afs.doc(path).update(data);
  }
  setReport(parc: string, data: IAuthorizedReports) {
    return this.afs.collection(`yards/${parc}/reports`).add(data);
  }
  getReports(parc: string): Observable<IAuthorizedReports[]> {
    return this.dhs.getCollection(`yards/${parc}/reports`);
  }
  getReportsOnce(parc: string): Promise<IAuthorizedReports[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/reports`);
  }
  getDepotsContracts(parc: string, client: string, type: string) {
    return this.dhs.getCollection(`yards/${parc}/clients/${client}/${type}`);
  }
  getContractsClientsCFS(
    parc: string,
    client: string,
    type: string
  ): Observable<
    IShuntingContract[] | IHandlingContract[] | IStorageContract[]
  > {
    return this.dhs.getCollection(`yards/${parc}/clientsCFS/${client}/${type}`);
  }
  checkContract(
    parc: string,
    client: string,
    type: string,
    gateType?: string,
    codeIso?: string,
    conSize?: string,
    conType?: string,
    emptyFull?: boolean
  ) {
    /* /yards/ASCOT-DEV/clients/CMA/storageContracts */
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${type}`)
      .ref.where("gateType", "==", gateType)
      .where("codeIso", "==", codeIso)
      .where("conSize", "==", conSize)
      .where("conType", "==", conType)
      .where("emptyFull", "==", emptyFull)
      .get();
  }

  getDepotsContractsOnce(
    parc: string,
    client: string,
    type: string
  ): Promise<any[]> {
    return this.dhs.getCollectionOnce(
      `yards/${parc}/clients/${client}/${type}`
    );
  }
  setDepotsContracts(
    parc: string,
    client: string,
    type: string,
    data: Partial<IStorageContract | IShuntingContract | IHandlingContract>
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${type}`)
      .add(data);
  }
  setContractsClientsCFS(
    parc: string,
    client: string,
    type: string,
    data: Partial<IStorageContract | IShuntingContract | IHandlingContract>
  ) {
    return this.afs
      .collection(`yards/${parc}/clientsCFS/${client}/${type}`)
      .add(data);
  }
  setShuntingContracts(
    parc: string,
    client: string,
    data: Partial<IShuntingContract>
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/shuntingContracts`)
      .add(data);
  }
  updateDepotsContract(
    path: string,
    data: Partial<IShuntingContract | IHandlingContract | IStorageContract>
  ) {
    return this.afs.doc(path).set(data);
  }
  getPreNotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/prenotif`);
  }

  getPreNotifDetails(parcId: string, preNotifId: string) {
    return this.dhs.getDocumentOnce(`yards/${parcId}/prenotif/${preNotifId}`);
  }

  getPrenotifDetails(parcId: string, id: string) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("prenotifId", "==", id)
      .get();
  }
  updatePreNotifDetailByPath(path: string, data) {
    return this.afs.doc(path).update(data);
  }
  getPreNotifRT(parcId: string, operator: string, type: string | string[]) {
    return this.dhs.getCollectionWhereByOrder(
      `yards/${parcId}/prenotif`,
      [{ left: "type", operator, right: type }],
      "createdAt",
      "desc"
    );
  }
  getBillablePreNotifRT(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/prenotif`, [
      { left: "type", operator: "in", right: ["lavage", "PTI"] },
    ]);
  }
  getSubPrenotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/subPrenotifs`);
  }
  getPosTasks(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/tasks`, [
      { left: "type", operator: "==", right: "Positioning" },
    ]);
  }

  searchForMatchingPrenotif(parcId: string, data) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("conType", "==", data.conType)
      .where("conSize", "==", data.conSize)
      .where("transporter", "==", data.transporter.id)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }
  getCounter(parcId: string, counterName: string) {
    return this.dhs.getDocument(`yards/${parcId}/counters/${counterName}`);
  }

  setPreNotif(parcId: string, id: string, data: Partial<IPreNotif>) {
    return this.afs.collection(`yards/${parcId}/prenotif`).doc(id).set(data);
  }
  setPreNotifDetail(
    parcId: string,
    preNotifId: string,
    containerId: string,
    data: any
  ) {
    return this.afs
      .collection(`yards/${parcId}/prenotif`)
      .doc(preNotifId)
      .collection("done")
      .doc(containerId)
      .set(data);
  }
  updatePreNotif(parcId: string, docId: string, data) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).update(data);
  }
  deletePreNotif(parcId: string, docId: string) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).delete();
  }
  deleteOrder(path: string) {
    return this.afs.doc(path).delete();
  }
  updateOrder(path: string) {
    return this.afs.doc(path).update({ isHidden: true });
  }

  addRole(data: any) {
    return this.afs.collection(`constants`).doc("roles").update(data);
  }

  getRoles() {
    return this.dhs.getDocument(`constants/roles`);
  }
  getRolesOnce() {
    return this.dhs.getDocumentOnce(`constants/roles`);
  }
  getDossierBcoByNumSerie(parcId: string, numSerie: string) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("dossiersBCO")
      .ref.where("containerNumSerie", "==", numSerie)
      .get();
  }

  async getRolesPremission() {
    return this.dhs.getDocumentOnce(`constants/roles`);
  }

  getRepportPerUser() {
    return this.dhs.getDocumentOnce(`constants/reportPerUserRole`);
  }

  getAllreports() {
    return this.dhs.getDocumentOnce(`constants/allReports`);
  }
  addReport(data: any) {
    return this.afs
      .collection(`constants`)
      .doc("reportPerUserRole")
      .update(data);
  }

  addReportData(data: any) {
    return this.afs.collection(`constants`).doc("allReports").update(data);
  }
  getRepportPeruser() {
    return this.dhs.getDocument(`constants/reportPerUserRole`);
  }

  setCustomer(id: string, data: any) {
    return this.afs.collection(`customers`).doc(id).set(data);
  }

  getCapacities() {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getCapacities`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getCapacitiesByPeriod(parcId, period) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getCapacities/${parcId}/${period}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }

  updateCustomer(id: string, data: any) {
    return this.afs.collection(`customers`).doc(id).update(data);
  }

  getAllParcsSub() {
    return this.dhs.getCollection(`yards`);
  }
  updateScheduleVbs(parcId, data) {
    return this.afs.collection(`yards`).doc(parcId).update(data);
  }
  getSubAppointments(id: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${id}/truckerBookings`);
  }
  getAllAppointments(id: string) {
    return this.dhs.getCollectionOnce(`yards/${id}/truckerBookings`);
  }

  updateAppointment(parcId, id, data) {
    return this.afs
      .collection(`yards`)
      .doc(parcId)
      .collection(`truckerBookings`)
      .doc(id)
      .update(data);
  }

  getAppointment(parcId, idBooking) {
    return this.dhs.getDocumentOnce(
      `yards/${parcId}/truckerBookings/${idBooking}`
    );
  }

  getAppointmentByBookingGateOut(
    parcId,
    bookingGateOut,
    plate,
    truckerCompany
  ) {
    return this.afs
      .collection(`yards/${parcId}/truckerBookings`)
      .ref.where("bookingId", "==", bookingGateOut)
      .where("plate", "==", plate)
      .where("truckerCompany", "==", truckerCompany)
      .where("action", "==", "pick_up")
      .limit(1)
      .get();
  }
  getClientOnce(parcId, clientId): Promise<IClient> {
    return this.dhs.getDocumentOnce(`yards/${parcId}/clients/${clientId}`);
  }

  getUser(uidUser) {
    return this.dhs.getDocumentOnce(`users/${uidUser}`);
  }
  getSubUser(uidUser) {
    return this.dhs.getDocument(`users/${uidUser}`);
  }
  async getExchangeRatesOnce() {
    return await this.dhs.getCollectionOnce("exchangeRates");
  }

  // *********** pump *************

  async getParcCurrency(parcId) {
    const parcData = await this.getYardOnceById(parcId);
    const parcCurrency = parcData.currency;
    let ISOcurrency = "EUR";

    if (parcCurrency === "Euro") {
      ISOcurrency = "EUR";
    }
    if (parcCurrency === "Dollar") {
      ISOcurrency = "USD";
    }

    return ISOcurrency;
  }

  getPumpCollection(parcId) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/stock_PUMP`);
  }
  async getPumpByRefPiece(parcId, refPiece) {
    const doc = await this.dhs.getDocumentOnce(
      `yards/${parcId}/stock_PUMP/${refPiece}`
    );

    if (doc.hasOwnProperty("pump")) {
      return {
        pump: doc.pump,
        totalReceivedQty: doc.hasOwnProperty("totalReceivedQty")
          ? doc.totalReceivedQty
          : 0,
      };
    } else {
      return { pump: 0, totalReceivedQty: 0 };
    }
  }

  // *********** pump *************

  // ******** carbon **********
  async saveOrderCarbon(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/saveCarbonFootprint`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  // ******** carbon **********
  addIncompleteGate(yard, gate) {
    return this.afs.collection(`yards/${yard}/incompleteGate`).add(gate);
  }

  async getPieceStockQuantityByRef(parcId: string, refPiece: string) {
    let stockQuantity = 0;
    try {
      await this.afs
        .collection(`yards/${parcId}/spare-parts-inventory`)
        .ref.where("refPiece", "==", refPiece)
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            stockQuantity += doc.data().stockQuantity;
          });
        });

      return stockQuantity;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  // *************** eval piece price ***************

  async validatePiecesPrice(orderPieces, parcId) {
    try {
      let estimatesWithPriceLowerThanPump = [];
      for (let piece of orderPieces) {
        const destimatesWithMissingPieces = await this.getBillsWithMissingRefs(
          parcId,
          piece.refPiece
        );

        const pump = await this.getPump(parcId, piece.refPiece);

        for (let estimate of destimatesWithMissingPieces) {
          if (pump) {
            if (estimate.price_piece < pump) {
              estimatesWithPriceLowerThanPump.push({ ...estimate, pump });
            }
          } else {
            const currentPump = piece.buyingPrice / piece.exchangeRate;

            if (estimate.price_piece < currentPump) {
              estimatesWithPriceLowerThanPump.push({
                ...estimate,
                pump: currentPump,
              });
            }
          }
        }
      }
      return estimatesWithPriceLowerThanPump;
    } catch (error) {
      console.log(error);
    }
  }

  async getBillsWithMissingRefs(parcId, refPiece) {
    try {
      let data = [];
      await this.afs
        .collection(`yards/${parcId}/devis`)
        .ref.where("missingPieces", "array-contains", refPiece)
        .orderBy("createdAt", "asc")
        .get()
        .then((res) => {
          res.docs.forEach((doc) => {
            if (
              !doc.data().hasOwnProperty("westimSent") &&
              !doc.data().hasOwnProperty("outYard")
            ) {
              const missingPieces = doc
                .data()
                .codeEntries.filter(
                  (ce) =>
                    ce.hasOwnProperty("spare_parts") &&
                    ce.spare_parts.length &&
                    ce.piece_ref === refPiece
                )
                .map((ce) => {
                  return {
                    price_piece: ce.price_piece,
                    piece_ref: ce.piece_ref,
                    devise_piece: ce.devise_piece,
                  };
                });

              if (missingPieces.length) {
                data.push({
                  ...missingPieces[0],
                  id: doc.id,
                  numSerie: doc.data().numSerie,
                });
              }
            }
          });
        });
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getPump(parcId, refPiece) {
    return this.afs
      .collection(`yards/${parcId}/stock_PUMP`)
      .doc(refPiece)
      .ref.get()
      .then((res) => {
        if (res.exists) {
          return res.data().pump;
        }
        return null;
      });
  }
  // *************** eval piece price ***************

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {}
}
