import { firestore, storage } from "firebase";
import { ParcAccessService } from "./../../shared/services/parc-access.service";
import { switchMap } from "rxjs/operators";
import { Observable, from } from "rxjs";
import {
  AngularFireUploadTask,
  AngularFireStorage,
} from "@angular/fire/storage";
import { formatDate } from "@angular/common";
import { ClientAccessService } from "./../../shared/services/client-access.service";
import { DateTime } from "luxon";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ContractService } from "../../shared/services/contract.service";
import moment from "moment-timezone";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  constructor(
    private clas: ClientAccessService,
    private pas: ParcAccessService,
    private readonly storage: AngularFireStorage,
    private db: AngularFirestore,
    private cs: ContractService
  ) {}
  FixDigit(num, fixed) {
    var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    return num.toString().match(re)[0];
  }
  singleGroupByBilling = (array: any[], keyGetter) => {
    // Return the end result
    const map = new Map();
    array.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  };
  uploadFileAndGetMetadata(mediaFolderPath: string, fileToUpload: File) {
    const { name } = fileToUpload;
    const filePath = `${mediaFolderPath}/${name}`;
    const uploadTask: AngularFireUploadTask = this.storage.upload(
      filePath,
      fileToUpload
    );
    return {
      uploadProgress$: uploadTask.percentageChanges(),
      downloadUrl$: this.getDownloadUrl$(uploadTask, filePath),
    };
  }
  async getDownloadBillUrl(data) {
    const parc = data.parc || data.parcId;
    let path = `${parc}/devis/${data.ref}`;
    try {
      const file1 = await storage().ref(path).getDownloadURL();
      return file1;
    } catch (error1) {
      path = `${parc}/devis/${data.numSerie}/${data.ref}`;
      try {
        const file2 = await storage().ref(path).getDownloadURL();
        return file2;
      } catch (error2) {
        const listFilesInFolder = await storage()
          .ref(`${parc}/devis/${data.numSerie}`)
          .list();
        path = listFilesInFolder.items[0].fullPath;
        const file3 = await storage().ref(path).getDownloadURL();
        return file3;
      }
    }
  }
  async getEirInStorage(data) {
    const parc = data.parc || data.parcId;
    return await storage()
      .ref(
        `${parc}/interchanges/${data.numSerie || data.chassisNumber}/${
          data.eirID
        }/eir-${data.numSerie || data.chassisNumber}.pdf`
      )
      .getDownloadURL();
  }
  getDownloadUrl(path) {
    return this.storage.ref(path).getDownloadURL();
  }

  private getDownloadUrl$(
    uploadTask: AngularFireUploadTask,
    path: string
  ): Observable<string> {
    return from(uploadTask).pipe(
      switchMap((_) => this.storage.ref(path).getDownloadURL())
    );
  }
  /**
   * Group an array of objects by a specific key
   * @param array Array of objects
   * @param key specific key
   */
  singleGroupBy(array: any[], key: string): { [key: string]: any[] } {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  }
  /**
   * Group array of objects by multiple keys
   * @param array Array of containers
   */
  multipleGroupBy(array: any[]): IMultipleGroupBy[] {
    const merged = array.reduce((r, { conType, conSize, ...rest }) => {
      const key = `${conType}-${conSize}`;
      r[key] = r[key] || { conType, conSize, result: [] };
      r[key]["result"].push(rest);
      return r;
    }, {});
    return Object.values(merged);
  }
  multipleGroupByInventory(array: any[]): IMultipleGroupBy[] {
    const merged = array.reduce((r, { refPiece, vendor, ...rest }) => {
      const key = `${refPiece}-${vendor}`;
      r[key] = r[key] || { refPiece, vendor, result: [] };
      r[key]["result"].push(rest);
      return r;
    }, {});
    return Object.values(merged);
  }
  multipleGroupByExcel(array) {
    const merged = array.reduce((a, e) => {
      if (e.etat === "D") {
        const key = `${e.conType}-${e.conSize}-${e.armateur}`;
        a[key] = ++a[key] || 0;
        return a;
      }
    }, {});
    return merged;
  }

  async multipleGroupByBills(
    array: any[],
    billingType: any,
    isRepair = false
  ): Promise<IMultipleGroupBy[]> {
    try {
      const merged = {};
      for (const {
        clientId,
        type_operation,
        parcId,
        numero_serie_conteneur,
        label,
        ...rest
      } of array) {
        // const clientData = await this.clas.getClientData(parcId, clientId);
        // const key = clientData.billType === "operation" ? `${clientId}-${type_operation}` : `${clientId}-${numero_serie_conteneur}`;

        const key =
          billingType === "operation"
            ? `${clientId}-${type_operation}`
            : `${clientId}-${numero_serie_conteneur}`;

        //if (clientData.billType === "operation" || clientData.billType === "container") {
        if (billingType === "operation" || billingType === "container") {
          merged[key] = merged[key] || { clientId, type_operation, result: [] };
          let items = {};
          if (isRepair && type_operation === "Devis")
            items = {
              ...rest,
              numero_serie_conteneur,
              parcId,
              clientId,
              type_operation,
            };
          else
            items = {
              ...rest,
              numero_serie_conteneur,
              parcId,
              clientId,
              type_operation,
              label,
            };
          merged[key]["result"].push(items);
        }
      }

      const list__grouped: IMultipleGroupBy[] = Object.values(merged);
      return list__grouped;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  /**
   * get the difference between today and the date in param in days
   * @param date Date as milliseconds timestamp
   * @returns {number} number of days
   */
  diffFromNow(date: number): number {
    return Math.max(
      Math.floor(DateTime.fromMillis(date).diffNow("days").toObject().days) *
        -1,
      0
    );
  }
  /**
   * Returns difference between two dates in a datePart
   * @param date1 First date
   * @param date2 Second date
   * @param datePart DatePart
   * @returns {number} difference by a date part
   */
  diffFromTwoDates(date1: number, date2: number, datePart: string): number {
    return Math.abs(
      DateTime.fromMillis(date1)
        .diff(DateTime.fromMillis(date2), datePart)
        .toObject()[datePart]
    );
  }
  // Add laborRate and lumpSumCoeff for each code entrie to save those prices when there is changement price //
  async updateEstimatePrices(data: any) {
    const batch = this.db.firestore.batch();
    const parc = data.parcId;
    const armateur = data.armateur;
    const clientId = data.clientId ? data.clientId : data.client;
    const id = data.estimateId ? data.estimateId : data.id;
    const bills = [];
    try {
      await this.db.firestore
        .collection("yards")
        .doc(parc)
        .collection("devis")
        .where("estimateId", "==", id)
        .where("westimCounter", "==", 3)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((dv) => {
            bills.push({
              id: dv.id,
              docRef: dv.ref,
              ...dv.data(),
            });
          });
        });
      const yardDoc = await this.db.firestore
        .collection(`yards`)
        .doc(parc)
        .get();
      if (!yardDoc.exists) throw new Error(`No yard with id: ${parc}`);
      const clientDoc = await this.db.firestore
        .collection("yards")
        .doc(parc)
        .collection("clients")
        .doc(clientId)
        .get();
      if (!clientDoc.exists)
        throw new Error(`No client setup in yard ${parc} with id: ${clientId}`);
      const clientData = clientDoc.data();
      const bill = bills[0];
      const operationBills = bills[0].codeEntries;
      const newEntries = [];
      for (let i = 0; i < operationBills.length; i++) {
        const bQLumpSum = [];
        const operation = operationBills[i];
        // get new lump_sum //
        const description =
          operation.shortcut && data.armateur === "HPL"
            ? operation.description_ENG
            : operation.shortcut && operation.description
            ? operation.description
            : null;
        const resultWs =
          operation.shortcut &&
          (await this.cs.getSpecificWs(
            parc,
            clientId,
            armateur,
            operation.code,
            description,
            operation.shortcut
          ));
        const result = operation.shortcut && (await resultWs.json());
        //  console.log("resultWs", result);
        operation.shortcut && bQLumpSum.push(result[0]);
        // Get new lump sum and if there is coeff from ws liner //
        const Lump_sum =
          operation.shortcut && bQLumpSum.length && bQLumpSum[0].Lump_sum;
        const with_coeff =
          operation.shortcut && bQLumpSum.length && bQLumpSum[0].with_coeff;

        const cedexLocationDamage1 = operation["cedex_code_location_damage_1"]
          ? operation["cedex_code_location_damage_1"]
          : operation["code_location_damage_1"];
        const outYard =
          bill.outYard &&
          !bill.onBoard &&
          bill.extraHours &&
          cedexLocationDamage1 &&
          cedexLocationDamage1.toLowerCase() === "m"
            ? clientData.openedHoursReef * clientData.extraHoursReef
            : bill.outYard &&
              !bill.onBoard &&
              cedexLocationDamage1 &&
              cedexLocationDamage1.toLowerCase() === "m"
            ? clientData.openedHoursReef
            : bill.outYard && !bill.onBoard && bill.extraHours
            ? clientData.openedHoursBox * clientData.extraHoursBox
            : bill.outYard && !bill.onBoard
            ? clientData.openedHoursBox
            : bill.onBoard &&
              bill.extraHours &&
              cedexLocationDamage1 &&
              cedexLocationDamage1.toLowerCase() === "m"
            ? clientData.openedHoursReefOnBoard *
              clientData.extraHoursReefOnBoard
            : bill.onBoard &&
              cedexLocationDamage1 &&
              cedexLocationDamage1.toLowerCase() === "m"
            ? clientData.openedHoursReefOnBoard
            : null;
        let laborRate = 0;
        let lumpSumCoeff = 0;
        laborRate = outYard
          ? outYard
          : cedexLocationDamage1 && cedexLocationDamage1.toLowerCase() === "m"
          ? clientData.tarifReefer
          : clientData.tarif;
        if (operation.shortcut && bQLumpSum.length >= 1) {
          lumpSumCoeff = operation["warranty"]
            ? 0
            : with_coeff
            ? Lump_sum
            : cedexLocationDamage1.toLowerCase() === "m" &&
              bill.extraHours &&
              !bill.onBoard
            ? Lump_sum * clientData.extraHoursReef
            : cedexLocationDamage1.toLowerCase() === "m" &&
              bill.extraHours &&
              bill.onBoard
            ? Lump_sum * clientData.openedHoursReefOnBoard
            : bill.extraHours && !bill.onBoard
            ? Lump_sum * clientData.extraHoursBox
            : Lump_sum;
        } else {
          lumpSumCoeff = 0;
        }
        let totalMO = 0;
        totalMO =
          (operation.nbr_pieces || 1) *
            operation.man_hour_per_piece *
            laborRate +
          (lumpSumCoeff || 0);
        totalMO += totalMO;
        newEntries.push({
          ...operation,
          laborRate: laborRate,
          lumpSumCoeff: lumpSumCoeff ? lumpSumCoeff : null,
        });
      }
      batch.update(bill.docRef, { codeEntries: newEntries });
      console.log("estimate updated!");
      await batch.commit();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Returns the total of quote between man hour and pieces
   * @param codeEntries List of quote operations
   * @param key Currency name
   * @param client Client data
   */
  calcBilling(codeEntries, client: IClient, billData): any {
    const currencies = {
      usd: "Dollar",
      euro: "Euro",
      pound: "Pound",
      tnd: "Dinar Tunisien",
    };
    return codeEntries.map((code) => {
      const codeLocationDamage = code["cedex_code_location_damage_1"]
        ? code["cedex_code_location_damage_1"]
        : code["code_location_damage_1"];
      let totalPr = 0;
      let totalMo = 0;
      const outYardTest =
        billData.outYard &&
        !billData.onBoard &&
        billData.extraHours &&
        codeLocationDamage &&
        codeLocationDamage.toLowerCase() === "m"
          ? client.openedHoursReef * client.extraHoursReef
          : billData.outYard &&
            !billData.onBoard &&
            codeLocationDamage &&
            codeLocationDamage.toLowerCase() === "m"
          ? client.openedHoursReef
          : billData.outYard && !billData.onBoard && billData.extraHours
          ? client.openedHoursBox * client.extraHoursBox
          : billData.outYard && !billData.onBoard
          ? client.openedHoursBox
          : billData.onBoard &&
            billData.extraHours &&
            codeLocationDamage &&
            codeLocationDamage.toLowerCase() === "m"
          ? client.openedHoursReefOnBoard * client.extraHoursReefOnBoard
          : billData.onBoard &&
            codeLocationDamage &&
            codeLocationDamage.toLowerCase() === "m"
          ? client.openedHoursReefOnBoard
          : null;
      const tarif = code["laborRate"]
        ? code["laborRate"]
        : outYardTest
        ? outYardTest
        : codeLocationDamage && codeLocationDamage.toLowerCase() === "m"
        ? client.tarifReefer
        : client.tarif;
      const lumpSumCoeff = code["lumpSumCoeff"]
        ? code["lumpSumCoeff"]
        : code.with_coeff
        ? code["Lump_sum"]
        : codeLocationDamage.toLowerCase() === "m" &&
          billData.extraHours &&
          !billData.onBoard
        ? code["Lump_sum"] * client.extraHoursReef
        : codeLocationDamage.toLowerCase() === "m" &&
          billData.extraHours &&
          billData.onBoard
        ? code["Lump_sum"] * client.extraHoursReefOnBoard
        : billData.extraHours && !billData.onBoard
        ? code["Lump_sum"] * client.extraHoursBox
        : code["Lump_sum"];
      totalPr = parseFloat(
        this.FixDigit((code.nbr_pieces || 1) * code["price_piece"], 2)
      );
      totalMo =
        parseFloat(
          this.FixDigit(
            (code.nbr_pieces || 1) * code.man_hour_per_piece * tarif,
            2
          )
        ) + (lumpSumCoeff || 0);
      return [
        {
          qte: (code.nbr_pieces || 1) * code.man_hour_per_piece,
          unit_price: tarif,
          type:
            codeLocationDamage && codeLocationDamage.toLowerCase() === "m"
              ? "MO Machine"
              : codeLocationDamage
              ? "MO Box"
              : null,
          devise: client["devise"],
          description: code.description_FR
            ? code.description_FR
            : code.description,
          totalPieces: parseFloat(totalMo.toFixed(2)),
        },
        {
          qte: code.nbr_pieces || 1,
          type:
            codeLocationDamage && codeLocationDamage.toLowerCase() === "m"
              ? "Machine"
              : codeLocationDamage
              ? "Box"
              : null,
          unit_price: code.price_piece,
          warranty: code.warranty ? code.warranty : false,
          description: code.description_FR
            ? code.description_FR
            : code.description,
          devise: currencies[code.devise_piece],
          totalPieces: parseFloat(totalPr.toFixed(2)),
        },
      ];
    });
  }
  currencyConvert(
    fromDevise: string,
    toDevise: string,
    total: number,
    rate: number
  ): number {
    if (fromDevise === toDevise) {
      return total;
    } else if (fromDevise === "Euro" && toDevise === "Dinar Tunisien") {
      return total * 3.1;
    } else if (fromDevise === "Dinar Tunisien" && toDevise === "Euro") {
      return rate ? total / rate : total / 0.32;
    } else if (fromDevise === "Dollar" && toDevise === "Dinar Tunisien") {
      return total * 2.81;
    } else if (fromDevise === "Dinar Tunisien" && toDevise === "Dollar") {
      return total * 0.35;
    } else if (fromDevise === "Dollar" && toDevise === "Euro") {
      return rate ? total / rate : total / 0.99;
    } else if (fromDevise === "Euro" && toDevise === "Dollar") {
      return total * 1.1;
    } else if (fromDevise === "Euro" && toDevise === "Pound") {
      return total * 0.84;
    } else if (fromDevise === "Pound" && toDevise === "Euro") {
      return rate ? total / rate : total / 1.18;
    } else if (fromDevise === "Dollar" && toDevise === "Pound") {
      return total * 0.76;
    } else if (fromDevise === "Pound" && toDevise === "Dollar") {
      return total * 1.3;
    } else if (fromDevise === "Dinar Tunisien" && toDevise === "Pound") {
      return total * 0.27;
    } else if (fromDevise === "Pound" && toDevise === "Dinar Tunisien") {
      return total * 3.68;
    }
  }

  /**
   * Returns list of clients and their corresponding longstanding value for each parc
   * @param parcId Parc name
   * @returns {IClientRules} Promise of {clientId:LongstandingValue}
   */
  lookUpClientRules(parcId: string): Promise<IClientRules> {
    return new Promise(async (resolve, reject) => {
      const clientsCollectionSnapshot: IClient[] =
        await this.clas.getAllClients(parcId);
      const fetchedClientRules: IClientRules = {};

      clientsCollectionSnapshot.forEach((clientDoc) => {
        const { longStanding, id } = clientDoc;
        fetchedClientRules[id] = { longStanding };
      });
      resolve(fetchedClientRules);
      reject((error) => {
        throw error;
      });
    });
  }
  /**
   * Returns total man hours for list of quote operation in hours and minutes
   * @param codeEntries List of quotes operations
   */
  estimateDateForEdi(codeEntries: ICodeEntries[]) {
    const totalManHours = codeEntries.reduce(
      (a, b) => a + b["man_hour_per_piece"],
      0
    );
    return DateTime.local()
      .plus({
        hours: Math.floor(totalManHours),
        minutes: (totalManHours - Math.floor(totalManHours)) * 10,
      })
      .toLocaleString({ locale: "fr" });
  }
  /**
   * Returns details array of EDI EMR standard
   * @param codeEntries List of quotes operations
   */
  detailsArrayForEdiMSK(codeEntries: ICodeEntries[]) {
    const detailsArray = codeEntries.map((codeEntry) => {
      if (!codeEntry["nbr_pieces"]) codeEntry["nbr_pieces"] = 1;
      return {
        DamageCode: codeEntry["code"].toUpperCase(),
        RepairCode: codeEntry["cedex_code_repair"].toUpperCase(),
        RepairLocCode: `${codeEntry[
          "cedex_code_location_damage_1"
        ].toUpperCase()}${
          codeEntry["cedex_code_location_damage_2"]
            ? codeEntry["cedex_code_location_damage_2"].toUpperCase()
            : "x"
        }NN`,
        PieceCount: codeEntry["nbr_pieces"].toString().padStart(3, "0"),
        MaterialAmount: (codeEntry["nbr_pieces"] * codeEntry["price_piece"])
          .toString()
          .replace(".", "")
          .padStart(12, "0"),
        ManHour: (codeEntry["man_hour_per_piece"] * codeEntry["nbr_pieces"])
          .toFixed(2)
          .replace(".", "")
          .padStart(4, "0"),
        ThirdParty: codeEntry["responsible"] || "W",
        SparePartsArray:
          codeEntry["spare_parts"] && codeEntry["spare_parts"].length
            ? codeEntry["spare_parts"].map((piece) => {
                return {
                  SparePartQuantity: (codeEntry.nbr_pieces * 10)
                    .toString()
                    .padStart(3, "0"),
                  SparePartReference: piece.ref_part,
                  SparePartSerialNumber: "",
                };
              })
            : [],
      };
    });
    return detailsArray;
  }
  detailsArrayForEdiOthers(data: any, client: IClient) {
    const detailsArray = data.codeEntries.map((op: ICodeEntries) => {
      const NumberPieces = op["nbr_pieces"] || 0;
      if (!op["nbr_pieces"]) op["nbr_pieces"] = 1;
      const cedexLocationDamage1 = op["code_location_damage_1"]
        ? op["code_location_damage_1"]
        : op["cedex_code_location_damage_1"];
      const cedexLocationDamage2 = op["code_location_damage_2"]
        ? op["code_location_damage_2"]
        : op["cedex_code_location_damage_2"]
        ? op["cedex_code_location_damage_2"]
        : "";
      const laborRate = op["laborRate"]
        ? op["laborRate"]
        : cedexLocationDamage1.toLowerCase() === "m"
        ? data.outYard && data.extraHours && !data.onBoard
          ? client.openedHoursReef * client.extraHoursReef
          : data.outYard && !data.onBoard
          ? client.openedHoursReef
          : data.onBoard && data.extraHours
          ? client.openedHoursReefOnBoard * client.extraHoursReefOnBoard
          : data.onBoard
          ? client.openedHoursReefOnBoard
          : client.tarifReefer
        : data.outYard && data.extraHours && !data.onBoard
        ? client.openedHoursBox * client.extraHoursBox
        : data.outYard && !data.onBoard
        ? client.openedHoursBox
        : client.tarif;
      const lumpSumCoeff = op["lumpSumCoeff"]
        ? op["lumpSumCoeff"]
        : op["with_coeff"]
        ? op["Lump_sum"]
        : cedexLocationDamage1.toLowerCase() === "m" &&
          data.extraHours &&
          !data.onBoard
        ? op["Lump_sum"] * client.extraHoursReef
        : cedexLocationDamage1.toLowerCase() === "m" &&
          data.extraHours &&
          data.onBoard
        ? op["Lump_sum"] * client.extraHoursReefOnBoard
        : data.extraHours && !data.onBoard
        ? op["Lump_sum"] * client.extraHoursBox
        : op["Lump_sum"];
      const FixedDigit = this.FixDigit(op.nbr_pieces * op.price_piece, 2);
      return {
        Code: op["code"],
        LocationCode: (
          cedexLocationDamage1.toUpperCase() +
          cedexLocationDamage2.toUpperCase()
        ).padEnd(4, "X"),
        ComponentCode: op["cedex_component_code"]
          ? op["cedex_component_code"].toUpperCase()
          : op["spare_parts"] && op["spare_parts"].length
          ? op["spare_parts"][0]["ref_part"]
          : "ZZ",
        DamageCode: op["damage_code"]
          ? op["damage_code"].toUpperCase()
          : op["cedex_damage_code"]
          ? op["cedex_damage_code"].toUpperCase()
          : "ZZ",
        Description: op["description"] || op["description_ENG"],
        Currency: op["devise_piece"],
        ComponentMaterialCode: op["cedex_material_code"]
          ? op["cedex_material_code"].toUpperCase()
          : op["spare_parts"] && op["spare_parts"].length
          ? op["spare_parts"][0]["ref_part"]
          : "ZZ",
        RepairCode: op["repair_code"]
          ? op["repair_code"].toUpperCase()
          : op["cedex_code_repair"]
          ? op["cedex_code_repair"].toUpperCase()
          : "ZZ",
        ManHourQty: parseFloat(
          this.FixDigit(op["man_hour_per_piece"] * (op["nbr_pieces"] || 1), 2)
        ),
        RefPiece: op["piece_ref"],
        UnitWeight: op["unit_weight"],
        MaterialCost: ["ONE", "OCL", "CMA", "HPL", "ZIM"].includes(
          data.armateur
        )
          ? parseFloat(
              this.FixDigit(
                op["nbr_pieces"] * op.price_piece + (lumpSumCoeff || 0),
                2
              )
            )
          : parseFloat(this.FixDigit(op["nbr_pieces"] * op["price_piece"], 2)),
        ResponsibleCode: op.responsible || "O",
        LaborRate: this.FixDigit(laborRate, 2),
        LaborTotal: ["ONE", "OCL", "CMA", "HPL", "ZIM"].includes(data.armateur)
          ? this.FixDigit(laborRate * op.man_hour_per_piece * op.nbr_pieces, 2)
          : this.FixDigit(
              laborRate * op.man_hour_per_piece * op.nbr_pieces +
                (lumpSumCoeff || 0),
              2
            ),
        MaterialTotal: parseFloat(
          this.FixDigit(op.nbr_pieces * op.price_piece, 2)
        ),
        HandlingTotal: "0",
        Tax: "0",
        MeasureUnit: op.measureUnit ? op.measureUnit.toUpperCase() : "CMT",
        Height: op["height"] || "0",
        Width: op["width"] || "0",
        Length: op["length"] || "0",
        TotalCost:
          parseFloat(FixedDigit) +
          laborRate * op.man_hour_per_piece * op.nbr_pieces +
          (lumpSumCoeff || 0),
        NumberPieces,
      };
    });
    return detailsArray;
  }

  /**
   * Returns standard EDI EMR for Data Exchange
   * @param data  Quote data
   * @param client Client data
   * @param totalMo Total Man/hour
   */
  generateEdiForMSK(data: IDevis, client: IClient, totalMo: number) {
    let totalMatAmount = 0;
    data["codeEntries"].forEach((codeEntry) => {
      if (!codeEntry["nbr_pieces"]) codeEntry["nbr_pieces"] = 1;
      totalMatAmount += codeEntry.price_piece * codeEntry.nbr_pieces;
    });

    const totalCost = totalMatAmount + totalMo;

    return {
      ShopCode: client["shopCode"],
      EstimateDate: formatDate(data.createdAt, "ddMMyyyy", "fr"),
      ContainerNumber: data["numSerie"],
      RepairMode: data["codeEntries"][0]["mode"],
      DamageCauseML: "1",
      ThirdPartyLocation: "",
      WOType: "W",
      EstimateNumber: data.id.substr(0, 10),
      TotalManHours: totalMo.toFixed(2).split(".").join("").padStart(4, "0"),
      TotalCost: totalCost.toFixed(2).replace(".", "").padStart(12, "0"),
      RemarkLine1: undefined,
      RemarkLine2: undefined,
      RemarkLine3: undefined,
      DetailsArray: this.detailsArrayForEdiMSK(data["codeEntries"]),
      test: totalMo,
    };
  }
  generateEdiForOthers(
    data: IDevis,
    client: IClient,
    parc: IParc,
    totalMo: number
  ) {
    let totalMatAmount = 0;
    data["codeEntries"].forEach((codeEntry) => {
      if (!codeEntry["nbr_pieces"]) codeEntry["nbr_pieces"] = 1;
      totalMatAmount += parseFloat(
        this.FixDigit(codeEntry.price_piece * codeEntry.nbr_pieces, 2)
      );
    });

    const totalCost = totalMatAmount + totalMo;
    const DetailsArray = this.detailsArrayForEdiOthers(data, client);
    const LaborRate =
      DetailsArray.reduce(
        (acc: any, curr: any) =>
          acc + Number(curr.LaborRate) * Number(curr.LaborTotal),
        0
      ) /
      DetailsArray.reduce(
        (acc: any, curr: any) => acc + Number(curr.LaborTotal),
        0
      );

    const convertDate = (date: number) =>
      DateTime.fromMillis(date, { zone: parc["timezone"] });
    return {
      ShopCode: client["shopCode"],
      DepotCode: client["parcWestimId"],
      DepotCodeArm: client["depotCodeArm"],
      Armateur: client["armateur"],
      CompanyCode: client["clientWestimId"],
      SendingDate: convertDate(Date.now()).toFormat("yyMMdd"),
      SendingHour: convertDate(Date.now()).toFormat("HHmm"),
      EnvelopeCounter: parc["codeEcoEnv"].toString(),
      SubEnvelopeCounter: parc["codeEcoSubEnv"].toString(),
      DateGateIn: convertDate(data.dateGateIn).toFormat("yyyyMMdd"),
      GateInHour: convertDate(data.dateGateIn).toFormat("HHmm"),
      DateGateInOutYard: convertDate(data.createdAt - 3289372).toFormat(
        "yyyyMMdd"
      ),
      GateInHourOutYard: convertDate(data.createdAt - 3289372).toFormat("HHmm"),
      EstimateDate: !["EVE", "HPL"].includes(data.armateur)
        ? convertDate(data.createdAt).toFormat("yyMMdd")
        : convertDate(data.createdAt).toFormat("yyyyMMdd"),
      EstimateHour: convertDate(data.createdAt).toFormat("HHmm"),
      EstimateNumber: data.id.substr(0, 14),
      FullEstimateNumber: data.id,
      EntranceEIRNumber: "45319",
      EntranceEIRDate:
        data.armateur !== "EVE"
          ? convertDate(data.dateGateIn).toFormat("yyMMdd")
          : convertDate(data.dateGateIn).toFormat("yyyyMMdd"),
      terminalCode:
        data.terminalCode === "AMERIQUE"
          ? "FRLHVG"
          : data.terminalCode === "EUROPE"
          ? "FRLHVG"
          : data.terminalCode === "FRANCE"
          ? "FRLHVF"
          : "FRLHVM",
      DDPCoverage: "0.00",
      LaborRate: LaborRate
        ? this.FixDigit(LaborRate, 2)
        : DetailsArray[0]["LaborRate"],
      Pictures:
        data.pictures && Array.isArray(data.pictures) && data.pictures[0]
          ? data.pictures
          : [],
      Photos:
        data.photos && Array.isArray(data.photos) && data.photos[0]
          ? data.photos
          : [],
      User: client["aliasEdi"] || "POULAIN",
      PrefixNumber: data["numSerie"].substr(0, 4),
      SuffixKeyNumber: data["numSerie"].substr(4),
      ISOType: data["codeIso"],
      ISOTypeDescription: `${data["conSize"]} ${data["conType"]}`,
      MaxGrossWeight: "0",
      IsEvac: data.isEvac || false,
      EmergencyRepair: data.outYard ? data.outYard : false,
      ContainerStatus: data["emptyFull"] ? "F" : "E",
      TotalEstimateCost: totalCost.toFixed(2),
      RemarkLine: data.note ? data.note : "",
      IsRevision: data.isRevision ? data.isRevision.toString() : "0",
      WestimState: data.WestimState ? data.WestimState : "O",
      DetailsArray,
    };
  }
  async returnStock(bill: any) {
    for (let index = 0; index < bill.codeEntries.length; index++) {
      const entry = bill.codeEntries[index];
      if (bill.sparePartsUsed && bill.sparePartsUsed.includes(entry.piece_ref))
        continue;
      else if (entry.piece_ref) {
        const matchingRef = await this.pas.getLastReservedPiece(
          bill.parcId,
          entry.piece_ref
        );
        if (matchingRef.empty) return;
        let remainingPieces = entry.nbr_pieces;
        for (let index = 0; index < matchingRef.docs.length; index++) {
          const docRef = matchingRef.docs[index];
          const { availableQuantity, stockQuantity, vendor } = docRef.data();
          const maxAvailableQtyIncrement = stockQuantity - availableQuantity;
          if (
            availableQuantity + entry.nbr_pieces === 0 &&
            stockQuantity === 0
          ) {
            await docRef.ref.delete();
            break;
          }
          if (remainingPieces > maxAvailableQtyIncrement) {
            await docRef.ref.update({
              availableQuantity: firestore.FieldValue.increment(
                maxAvailableQtyIncrement
              ),
            });
            remainingPieces -= maxAvailableQtyIncrement;

            continue;
          } else {
            await docRef.ref.update({
              availableQuantity:
                firestore.FieldValue.increment(remainingPieces),
            });

            break;
          }
        }
      }
    }
  }
  /**
   *
   * @param status : string
   * @param dict : object of shape { word : [...equivalent word] , word2 : [...equivalentword2] , ...}
   * the function takes a string and will return one of the keys of the dictionary
   */

  formatStatus(status: string, dict?: object): string {
    if (!dict) {
      dict = {
        Enregistré: [
          "saved" /*,"delivered","opened","clicked","unsubscribed","stored"*/,
          ,
        ],
        Envoyé: [
          "accepted" /*,"delivered","opened","clicked","unsubscribed","stored"*/,
          ,
        ],
        Reçu: [
          "delivered",
          "received" /*,"accepted","opened","clicked","unsubscribed","stored"*/,
        ],
        "Échec Temporaire": ["temporary fail"],
        Échec: ["permanant fail", "failed", "rejected"],
        "Échec (Volume maximum atteint)": ["overload"],
      };
    }
    const keys = Object.keys(dict);

    for (let i = 0; i < keys.length; i++) {
      if (dict[keys[i]].includes(status.toLowerCase())) {
        return keys[i] || "";
      }
    }
  }
}
export const differenceBetweenDays = (date1, date2, timezone) => {
  const timeZoneParc = timezone || "Europe/Paris";

  const convertedDate1 = moment.tz(date1, timeZoneParc).startOf("day");
  const convertedDate2 = moment.tz(date2, timeZoneParc).startOf("day");

  const daysBetweenDates = convertedDate2.diff(convertedDate1, "days");

  return daysBetweenDates < 0
    ? daysBetweenDates - 1
    : Math.abs(daysBetweenDates) + 1;
};

export interface IClientRules {
  [id: string]: { longStanding: number };
}
export interface IMultipleGroupBy {
  conSize: number;
  conType: string;
  result: IContainer[];
}
